import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link, withPrefix } from 'gatsby'
import { ImStopwatch } from 'react-icons/im';
import { BiCalendarAlt } from 'react-icons/bi';
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import ShareButtons from '../components/share/share.component'
import Quiz from '../components/Quiz/Quiz'
import { Disqus } from 'gatsby-plugin-disqus';

export const BlogPostTemplate = ({
    content,
    contentComponent,
    description,
    tags,
    featuredimage,
    title,
    readingTime,
    date,
    id,
    questions,
}) => {
    const PostContent = contentComponent || Content;
    const titles = `Leer --> ${title} `
    const tagss = tags;
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const twitterHandle = "crisjc8"
    const disqusConfig = {
        url: url,
        identifier: id,
        title: title,
    }
    const preguntas = questions
    const image = featuredimage?.publicURL
        ? `https://www.crisweb.me${featuredimage.publicURL}`
        : `https://www.crisweb.me${withPrefix("/") + "img/skeleton-blog.png"}`;
    return (
        <section className="section pl-4">
            <Helmet>
                <title>{`${title} | Blog`}</title>
                <meta name="description" content={description} />

                {/* Open Graph */}
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="article" />
                <meta property="og:image" content={image} />
                <meta property="og:image:secure_url" content={image} />
                <meta property="og:site_name" content='Blog Crisweb' />
                <meta property="og:locale" content="es_ES" />

                {/* Twitter */}
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
                <link rel="canonical" href={url} />
            </Helmet>
            <div className="container content">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <h1 className="title is-size-4-mobile is-size-3-tablet is-size-3 has-text-weight-bold is-bold-light">
                            {title}
                        </h1>
                        <p>{description}</p>
                        <p> <BiCalendarAlt />    {date}        ,<ImStopwatch />    {readingTime}</p>
                        <span style={{ color: "#345B83" }}>Share with:</span>
                        <div>
                            <ShareButtons title={titles} url={url} twitterHandle={twitterHandle} tags={tagss} />
                        </div>
                        <div className="custom-shape-divider-top-1608848682">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                                preserveAspectRatio="none">
                                <path d="M602.45,3.86h0S572.9,116.24,281.94,120H923C632,116.24,602.45,3.86,602.45,3.86Z"
                                    className="shape-fill"></path>
                            </svg>
                        </div>
                        <PostContent content={content} />
                        {preguntas && preguntas.length > 0 && <Quiz questions={preguntas} />}
                        {tags && tags.length ? (
                            <div style={{ marginTop: '4rem', borderTop: '2px solid #6A70B2', borderBottom: '2px solid #6A70B2', paddingTop: '2rem', paddingBottom: '2rem' }}>
                                <h2 style={{ fontSize: '1.8rem', marginBottom: '1.5rem', color: '#333' }}>Explora más temas</h2>
                                <ul style={{ listStyle: 'none', padding: 0, display: 'flex', flexWrap: 'wrap' }}>
                                    {tags.map((tag) => (
                                        <li key={tag + 'tag'} style={{ margin: '0.5rem' }}>
                                            <Link
                                                to={`/tags/${kebabCase(tag)}/`}
                                                style={{
                                                    textDecoration: 'none',
                                                    border: '1px solid #6A70B2',
                                                    color: '#6A70B2',
                                                    padding: '0.5rem 1rem',
                                                    borderRadius: '0.5rem',
                                                    transition: 'background-color 0.3s, color 0.3s',
                                                    display: 'inline-block',
                                                    cursor: 'pointer',
                                                    fontWeight: 'normal',
                                                }}
                                                onMouseEnter={(e) => { e.target.style.backgroundColor = '#6A70B2'; e.target.style.color = '#fff'; }}
                                                onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; e.target.style.color = '#6A70B2'; }}
                                            >
                                                {tag}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        ) : null}
                        <Disqus config={disqusConfig} />
                    </div>
                </div>
            </div>
        </section>
    )
}

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    readingTime: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.string,
    featuredimage: PropTypes.object,
}

const BlogPost = ({ data }) => {
    const { markdownRemark: post } = data

    console.log("Frontmatter completo:", post.frontmatter);

    return (
        <Layout>
            <BlogPostTemplate
                content={post.html}
                readingTime={post.fields.readingTime.text}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                helmet={
                    <Helmet titleTemplate="%s | Blog">
                        <title>{`${post.frontmatter.title}`}</title>
                        <meta
                            name="description"
                            content={`${post.frontmatter.description}`}
                        />
                    </Helmet>
                }
                date={post.frontmatter.date}
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
                featuredimage={post.frontmatter.featuredimage}
                questions={post.frontmatter.questions}
                id={post.frontmatter.id}
            />
            <HTMLContent content={post.frontmatter.references} />
            <div dangerouslySetInnerHTML={{ __html: post.frontmatter.references }} />
        </Layout>
    )
}

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
            publicURL
        }
        questions {
            question
            options
            correctAnswer
            feedback
        }
      }
      fields {
        readingTime {
          text 
        }
      }
    }
  }
`
