import React, { useEffect } from "react";
import { Helmet } from 'react-helmet'
import Footer from './Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import { withPrefix } from 'gatsby'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
import BuyMeCoffeeButton from "./BuymeCoffee/BuymeCoffee";
deckDeckGoHighlightElement();

const TemplateWrapper = ({ children }) => {
  useEffect(() => {
    window.onload = function() {
      var images = document.querySelectorAll('img[src="https://app-blue-upload.s3.amazonaws.com/BOT/Bot8360-1710719893984.png"]');
      images.forEach(function(img) {
        img.setAttribute('alt', 'avatar chat');
      });
    };
  }, []); 
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <script data-ad-client="ca-pub-1376145166708022" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

      </Helmet>
      <Navbar />
      <div style={{
        textAlign: "left !important"
      }}>{children}</div>
      <ScrollToTopButton />
      <BuyMeCoffeeButton />
      <Footer />
    </div>
  )
}

export default TemplateWrapper
